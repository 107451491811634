import React from "react";

import AboutBackgroundImage from "../Assets/iphone2.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";



const Design = () => {

  const handleDownloadBela = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Bela Bela Case Study1.pdf`;  // Path to the PDF file
    link.setAttribute('download', 'Bela Bela Case Study1.pdf');  // Specify a name for the downloaded file
    
    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  
  const handleDownloadNyandeni = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Nyandeni Case Study1.pdf`;  // Path to the PDF file
    link.setAttribute('download', 'Nyandeni Case Study1.pdf');  // Specify a name for the downloaded file
    
    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="about-section-container">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Design</p>
        <h1 className="primary-heading">
        We Designed Perfect
        App for You
        </h1>
       
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        {' '}
        Instant Geo tagging pin pointing your exact location on the map.
        </p>
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        {' '}
        Community updates​ Stay informed with geo-targeted newsfeeds

        </p>
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        {' '}
        Streamlined Access to Government Services​
        </p>
       
      
        <div  className="about-buttons-container">
        
    
     
       
          <button className="secondary-button"
        onClick={handleDownloadBela}
          >
            Bela Bela
            </button>
            <div style={{marginLeft:"15px"}}></div>
            <button className="secondary-button"
        onClick={handleDownloadNyandeni}
          >
           Nyandeni 
            </button>
        </div>
      </div>
    </div>
  );
};

export default Design;
