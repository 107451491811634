import React from "react";

import AboutBackgroundImage from "../Assets/Saly-26.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const Questions = () => {
  return (
    <div className="about-section-container">
      
     
      <div className="features-section-text-container">
        <p className="primary-subheading">Question</p>
        <h1 className="primary-heading">
        Have any Question?
        </h1>
        <p className="primary-text-question">
        Can we link our call-center administrators?
        </p>
        <p className="primary-text">
        Yes, admins can be linked to the application on existing infrastructure or systems
        </p>

        <p className="primary-text-question">
        How do we safeguard against your data?
        </p>
        <p className="primary-text">
        we are POPIA compliant due to all our servers being hosted by Amazon Web Services.
        Any data that’s being sent and received through the platform, only the end users can access it.
        </p>

        <p className="primary-text-question">
        How do we protect against job losses in the country?
        </p>
        <p className="primary-text">
        The app enhances government services by using technology to improve efficiency and transparency, helping officials assist citizens more effectively. 
        It supports the workforce rather than replacing it, fostering better communication between the government and the public.
        </p>
     
        
      </div>

      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default Questions;
