import React from "react";
import Logo from "../Assets/Logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";


const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
       
      </div>
      <div className="footer-icons">
      {/* color="#1DA1F2" */}
          <BsTwitter   size={50} 
          
          onClick={()=>{
            window.open("https://x.com/SayaSetona", "_blank");
          }}/>
        {/* color="#0866FF" */}
          <FaFacebookF   onClick={()=>{
            window.open("https://www.facebook.com/SayaTechno/", "_blank");
          }}  size={50} />
        </div>
      <div className="footer-section-two">
       
        <div className="footer-section-columns">
          <span style={{fontSize:"17px"}}><FaPhone /> +2771 237 7269</span>
          <span style={{fontSize:"17px"}}> <IoMail /> info@saya-setona.co.za</span>
          
          
        </div>
        <div className="footer-section-columns">
        
        </div>
        <div className="footer-section-columns">
        <span style={{fontSize:"17px"}}> <IoLocationSharp size={20} />
          Innovation Centre Mark Shuttleworth Street</span>
          <span >The Innovation Hub Tshwane South Africa</span>
        </div>
        <div className="footer-section-columns">
        
        </div>
      </div>
    </div>
  );
};

export default Footer;
