import React from "react";

import salomePic from '../Assets/Salome.jpg'
import AmrPic from '../Assets/Amr.jpg'
import NomaPic from '../Assets/Noma.jpg'
import TPic from '../Assets/T.jpg'
import MoPic from '../Assets/Mo.jpg'
import DidiPic from '../Assets/Didi.jpg'
import TinashePic from '../Assets/Tinahse.jpg'
import IshPic from '../Assets/Ish.jpg'

import TeamMember from "./TeamMember";
const Team = () => {
  return (
    <div className="contact-page-wrapper">
      <p className="primary-subheading">Team</p>
      <h1 className="primary-heading">Meet Our Team</h1>
      <h1 style={{textAlign:'center'}}className="sub-heading">The Geeks</h1>
      <div className="team-container-wrapper">
      <TeamMember Name={"Moepi Setona"} Picture={MoPic} JobDesc={"Founder | Sales Business Executive "}/>
        <TeamMember Name={"Salome Mothapo"} Picture={salomePic} JobDesc={"Senior System Tester and Engineer"}/>
       
        <TeamMember Name={"Didintle Diakanyo"} Picture={DidiPic} JobDesc={"Information Architect"}/>
        <TeamMember Name={"Amr Ibrahim"} Picture={AmrPic} JobDesc={"Senior Front-End Dev UX/UI Designer"}/>
        
      </div>
      <div className="team-container-wrapper">
      <TeamMember Name={"Tshegofatso Molokoane"} Picture={TPic} JobDesc={"Sales Associate"}/>
       
        <TeamMember Name={"Tinashe"} Picture={TinashePic} JobDesc={"Senior Cloud Engineer"}/>
        <TeamMember Name={"Ishmail Mahlagare"} Picture={IshPic} JobDesc={"Business Analyst"}/>
      </div>
    </div>
  );
};

export default Team;
