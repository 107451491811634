import React from "react";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div style={{marginTop:"50px"}}>
      {/* <button className="secondary-button">Contact Us</button> */}
      </div>
    </div>
  );
};

export default Contact;
