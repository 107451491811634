import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import IBMLogo from '../Assets/IBM.jpg'
import AWSLogo from '../Assets/AWS.png'
import DPSALogo from '../Assets/DPSA.jpg'
import DDMLogo from '../Assets/DDMLogo.png';
import UNDP from '../Assets/UNDP.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Partners = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Partners</p>
        <h1 className="primary-heading">Who are Our Partners</h1>
       
        <Carousel  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{maxWidth:"400px", display:'flex', justifyContent:'center'}}>
                    <img src={IBMLogo} />
                    <p className="legend">IBM</p>
                </div>
                <div style={{maxWidth:"400px"}}>
                <img src={AWSLogo} />
                    <p className="legend">AWS</p>
                </div>
                <div style={{maxWidth:"400px"}}>
                
                <img src={DPSALogo} />
                    <p className="legend">DPSA</p>
                    </div>
                <div style={{maxWidth:"400px"}}>
                
                <img src={DDMLogo} />
                    <p className="legend">DDM</p>
                    </div>
                <div style={{maxWidth:"400px"}}>
                
                <img src={UNDP} />
                    <p className="legend">UNDP</p>
                </div>
                
            </Carousel>
           
       
      </div> 
    </div>
  );
};

export default Partners;
