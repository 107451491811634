import React from "react";

import AboutBackgroundImage from "../Assets/CompanyLogo.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
       About Saya Setona
        </h1>
        <p style={{fontSize:"18px "}} className="primary-text">
        Let's Talk Platform is an AWS-powered communication platform designed to streamline interactions between government officials and citizens.
        </p>
        <p  style={{fontSize:"18px "}} className="primary-text">
        It provides a centralized, intuitive interface that allows real-time communication, efficient tracking of queries, and transparent reporting.        </p>
        <p  style={{fontSize:"18px "}} className="primary-text">
        By consolidating communication channels, it reduces delays, increases response times, and enhances service delivery. Citizens can easily submit inquiries or feedback, while government officials can efficiently manage and resolve issues.        </p>
        <p   style={{fontSize:"18px "}}className="primary-text">
        This solution eliminates the need for manual processes and scattered channels, improving transparency, accountability, and fostering stronger citizen-government relationships.        </p>
        {/* <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default About;
