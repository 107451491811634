import React from "react";

import AboutBackgroundImage from "../Assets/i12.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoMdThumbsUp } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";



const Features = () => {
  return (
    <div className="about-section-container">
      
     
      <div className="features-section-text-container">
        <p className="primary-subheading">Features</p>
        <h1 className="primary-heading"> Our App Features </h1>
    
        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        
        <IoMdThumbsUp size={25} color="#DD883F" /> {"   "}  Two way communication  </p>
          
        <p className="primary-text">
        The app introduces a two-way communication between citizens and their local government.
         We enable users to request services, report incidents and see all trainings within the area
        </p>
        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        <FaBell  size={25} color="#DD883F" />
          {"   "}
          Geolocation
        </p>


        <p className="primary-text">
        The app also uses geolocation to ensure you get Instant Messaging on what's happening in your area.
        </p>

        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        <HiUsers size={25} color="#DD883F" />
          {"   "}
          Our App is Free
        </p>

        <p className="primary-text">
        Our app is also completely free to download. Use our app to better be in the know about what your local government is doing for you
        </p>
       
       
       
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default Features;
